const formatCategory = (categoryDetails: TRelevantCategory) =>
  categoryDetails.parentKey
    ? {
        l1Category: {
          key: categoryDetails.parentKey,
          id: categoryDetails.parentId,
        },
        l2Category: {
          key: categoryDetails.key,
          id: categoryDetails.id,
        },
      }
    : {
        l1Category: {
          key: categoryDetails.key,
          id: categoryDetails.id,
        },
      };

type TGetCategoryFromId = ReturnType<typeof formatCategory>;

const getCategoryFromId = (categoryOptions, categoryId): TGetCategoryFromId => {
  const id = Number(categoryId);

  if (Number.isNaN(id) || id === 0) {
    return {} as TGetCategoryFromId;
  }

  const categoryDetails = categoryOptions.find((option) => option.id === id);
  return formatCategory(categoryDetails);
};

export default getCategoryFromId;
