import React from 'react';
import { I18nContextProvider } from '@ecg-marktplaats/js-react-i18n';

export default function withI18n(ComposedComponent, i18n) {
  const WithI18n = (props) => (
    <I18nContextProvider i18nInstance={i18n}>
      <ComposedComponent {...props} />
    </I18nContextProvider>
  );

  WithI18n.getInitialProps = (ctx) => {
    let composedProps = {};

    if (ComposedComponent.getInitialProps) {
      composedProps = ComposedComponent.getInitialProps(ctx);
    }

    return composedProps;
  };

  return WithI18n;
}
