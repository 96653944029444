const minDigitCharCode = 48;
const maxDigitCharCode = 57;
const minAlphabetCharCode = 97;
const maxAlphabetCharCode = 122;

function formatPostCode(value) {
  let output = '';
  const lowerCasedValue = decodeURIComponent(value).toLowerCase();

  // eslint-disable-next-line no-restricted-syntax
  for (const char of lowerCasedValue) {
    const charCode = char.charCodeAt(0);

    const isNumber = charCode >= minDigitCharCode && charCode <= maxDigitCharCode;
    const isLetter = charCode >= minAlphabetCharCode && charCode <= maxAlphabetCharCode;
    if (isNumber || isLetter) {
      output += char;
    }
  }

  return output.toUpperCase();
}

export default formatPostCode;
