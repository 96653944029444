import React from 'react';
import Header from '@/client/components/Header';

interface AppShellProps {
  children: React.ReactNode;
  seller?: TSeller;
  categories?: TCategories;
  searchRequest: TSearchRequest;
  attributeHierarchy: TAttributeHierarchy;
  searchCategory: number;
  searchCategoryOptions?: Array<TSearchCategoryOption>;
  seoFriendlyAttributes?: string[];
  alternativeLocales?: TAlternativeLocaleType[];
  headerProps: THeaderProperties;
  reloadPageOnSearch?: boolean;
}

const AppShell = ({
  seller,
  searchRequest,
  attributeHierarchy,
  seoFriendlyAttributes,
  headerProps,
  searchCategory,
  searchCategoryOptions,
  children,
  reloadPageOnSearch = false,
}: AppShellProps) => {
  const searchRequestObject = {
    ...searchRequest,
    seller,
    seoFriendlyAttributes,
    attributes: attributeHierarchy, // this is from facets, overwriting the one from searchRequest
  };

  return (
    <>
      <Header
        {...headerProps}
        searchRequestObject={searchRequestObject}
        searchCategoryId={searchCategory}
        searchCategoryOptions={searchCategoryOptions}
        reloadPageOnSearch={reloadPageOnSearch}
      />
      {children}
    </>
  );
};

export default AppShell;
