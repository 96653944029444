const legacyAttributesOrder = [
  'brand',
  'model',
  'fuel',
  'transmission',
  'region',
  'typeOfProperty',
  'material',
  'breed',
  'condition',
];

const maxSeoAttributes = 2; // max number of attributes that should be in SEO part of the URL (i.e., before the #)

export { legacyAttributesOrder, maxSeoAttributes };
