const getIdFromCategory = (category) => (category || null) && Number(category.id);

const isRemoveAllAttributes = (searchCategory, SROCategory) => {
  const { l1Category, l2Category } = searchCategory;
  const { SROL1Category, SROL2Category } = SROCategory;

  const isSameL1 = getIdFromCategory(l1Category) === getIdFromCategory(SROL1Category);
  const isSameL2 = getIdFromCategory(l2Category) === getIdFromCategory(SROL2Category);
  const isL1ToL2 = !SROL2Category || isSameL2;

  return !isSameL1 && !isL1ToL2;
};

export default isRemoveAllAttributes;
