export default {
  baseCategoriesURL: 'l',
  attributes: 'f',
  query: 'q',
  page: 'p',
  sorting: 's',
  seller: 'u',
  traits: 't',
  nonTrackingSeparator: '|',
  nonTrackingKey: '#',
  nonTrackingDefiner: ':',
  seoFriendlyMergeKey: '+',
  pageOffset: 'offset',
  Limit: 'limit',
  distance: 'distanceMeters',
  postcode: 'postcode',
  view: 'view',
  asSavedSearch: 'asSavedSearch',
  bypassSpellingSuggestion: 'bypassSpellingSuggestion',
  offeredSince: 'offeredSince',
  textAttributes: 'ta',
  textAttributesSeparator: '~',
  disableRedirect: 'dr',
};
