// Data Driven Content / insights
export const MIN_NUMBER_OF_LISTINGS_DDI = 10;
export const MAX_NUMBER_OF_SENTENCES_DDI = 6;
export const MAX_NUMBER_OF_CENTRED_SENTENCES_DDI = 3;

export const NUMBER_OF_CENTS_IN_A_EURO = 100;

export default {
  permanentRedirectCode: 301,
  temporaryRedirectCode: 302,
  // on 2dehands this attribute is always added, causing links to be unintentionally masked
  noMaskHashAttributes: new Set(['Language']),
  attributeKeysToExcludeForMetaTags: new Set(['Language']),
};
