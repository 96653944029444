import VIEW_OPTIONS from '@/constants/viewOptions';

const attributeKeys = {
  MILEAGE: 'mileage',
  PRICE_CENTS: 'PriceCents',
  LANGUAGE: 'Language',
  OFFERED_SINCE: 'offeredSince',
  AS_SAVED_SEARCH: 'asSavedSearch',
  CAR_MODEL: 'model',
  OPTIONS: 'options',
  PRICE_TYPE: 'priceType',
};

export const PRICE_TYPE_ATTRIBUTE_VALUE_KEY_NL = 'Lease';
export const PRICE_TYPE_ATTRIBUTE_VALUE_KEY_FR = 'Leasing';
export const PRICE_TYPE_SALE_ATTRIBUTE_VALUE_ID = 10882;
export const PRICE_TYPE_LEASE_ATTRIBUTE_VALUE_ID = 10883;

const attributeValues = {
  [attributeKeys.LANGUAGE]: ['nl-NL', 'nl-BE', 'fr-BE'],
  [attributeKeys.OFFERED_SINCE]: ['Vandaag', 'Gisteren', 'Een week', 'Altijd'],
  [attributeKeys.PRICE_TYPE]: [PRICE_TYPE_SALE_ATTRIBUTE_VALUE_ID, PRICE_TYPE_LEASE_ATTRIBUTE_VALUE_ID],
};

const defaults = {
  attributes: ['Altijd'],
  limit: 30,
  view: VIEW_OPTIONS.LIST_VIEW,
};

export { attributeKeys, attributeValues, defaults };
