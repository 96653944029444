import enrichSearchBarConfig from './enrichSearchBarConfig';

export default function headerConfig(props: THeaderProperties) {
  const {
    isSticky,
    showNavBar,
    navBar,
    showSearchBar,
    searchBar,
    searchCategoryId,
    searchCategoryOptions,
    searchRequestObject,
    xsrfToken,
    experiments,
    logoUrls,
  } = props;

  const searchBarData = {
    searchBar,
    searchCategoryId,
    searchCategoryOptions,
    searchRequestObject,
    experiments,
  };

  const searchBarConfig = enrichSearchBarConfig(searchBarData);

  return {
    isSticky,
    showNavBar,
    navBar,
    showSearchBar,
    searchBar: searchBarConfig,
    xsrfToken,
    isWithBiggerTouchAreas: true,
    logoUrls,
  };
}
