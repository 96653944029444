import { seoUrlPart } from '@ecg-marktplaats/aurora-js-formatters';
import ACCENTED_CHAR_LIST from '@/utils/formatters/searchQuery/accentedCharacterList';
import supportedBooleanOperators from '@/constants/supportedBooleanOperators';

const formatWord = (input: string): string => {
  if (input === null || typeof input === 'undefined') {
    return '';
  }

  if (typeof input === 'number') {
    return String(input);
  }

  return Array.from(input)
    .map((char) => {
      if (ACCENTED_CHAR_LIST.includes(char)) {
        return encodeURIComponent(char);
      }
      const doSlugify = false;
      return encodeURIComponent(seoUrlPart(char, doSlugify));
    })
    .join('');
};

const changeCasing = (word: string) => {
  const shouldKeepCasing = supportedBooleanOperators.includes(word);
  return shouldKeepCasing ? word : word.toLowerCase();
};

const formatForURL = (input: string): string => input.split(' ').map(formatWord).map(changeCasing).join('+');

export default formatForURL;
