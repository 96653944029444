import React, { ReactNode } from 'react';
import { PageContainer as PageContainerComponent, PageContent } from '@hz-design-system/web-ui';

interface PageContainerProps {
  children?: ReactNode;
  className?: string;
  isInitialLoad?: boolean;
}

const PageContainer: React.FC<PageContainerProps> = ({ children = null, className, isInitialLoad }) => {
  return (
    <PageContainerComponent as="main" className={className || ''} modifiers={[isInitialLoad ? 'fullHeight' : '']}>
      <PageContent as="div" id="content">
        {children}
      </PageContent>
    </PageContainerComponent>
  );
};

export default PageContainer;
