const CATEGORIES_L1 = {
  DIEREN_EN_TOEBEHOREN: 395,
  CONTACTEN_EN_BERICHTEN: 378,
  DIENSTEN_EN_VAKMENSEN: 1098,
  HUIZEN_EN_KAMERS: 1032,
  HUIS_EN_INRICHTING: 504,
  DAMES_KLEREN: 621,
  VACATURES: 167,
  VAKANTIE: 856,
  CARS: 91,
  DIVERSEN: 428,
};

export const CATEGORIES_L2 = {
  VANS: 95,
  TRUCKS: 159,
  OLDTIMERS: 137,
  CAMPINGS: 859,
  HUIZEN_TE_KOOP: 2142,
  KINDERFEESTJES_EN_ENTERTAINERS: 2084,
  VACATURES_SCHOONMAAK_EN_FACILITAIRE_DIENSTEN: 1167,
  DIERENVOEDING: 399,
  OVERIGE_DIEREN_ACCESSOIRES: 397,
};

export const OTHER_VEHICLE_TYPES = [CATEGORIES_L2.VANS, CATEGORIES_L2.TRUCKS, CATEGORIES_L2.OLDTIMERS];

// we don't want to show Data Driven insights for these categories
export const DDI_EXCLUDED_CATEGORIES = new Set([
  CATEGORIES_L1.DIEREN_EN_TOEBEHOREN,
  CATEGORIES_L1.DIVERSEN,
  CATEGORIES_L2.CAMPINGS,
  CATEGORIES_L2.HUIZEN_TE_KOOP,
  CATEGORIES_L2.KINDERFEESTJES_EN_ENTERTAINERS,
  CATEGORIES_L2.VACATURES_SCHOONMAAK_EN_FACILITAIRE_DIENSTEN,
]);

export default CATEGORIES_L1;
