export default [
  '\u00E0', // à  [LATIN SMALL LETTER A WITH GRAVE
  '\u00E1', // á  [LATIN SMALL LETTER A WITH ACUTE]
  '\u00E2', // â  [LATIN SMALL LETTER A WITH CIRCUMFLEX]
  '\u00E3', // ã  [LATIN SMALL LETTER A WITH TILDE]
  '\u00E4', // ä  [LATIN SMALL LETTER A WITH DIAERESIS]
  '\u00E5', // å  [LATIN SMALL LETTER A WITH RING ABOVE]
  '\u00C0', // À  [LATIN CAPITAL LETTER A WITH GRAVE]
  '\u00C1', // Á  [LATIN CAPITAL LETTER A WITH ACUTE]
  '\u00C2', // Â  [LATIN CAPITAL LETTER A WITH CIRCUMFLEX]
  '\u00C3', // Ã  [LATIN CAPITAL LETTER A WITH TILDE]
  '\u00C4', // Ä  [LATIN CAPITAL LETTER A WITH DIAERESIS]
  '\u00C5', // Å  [LATIN CAPITAL LETTER A WITH RING ABOVE]
  '\u00C7', // Ç  [LATIN CAPITAL LETTER C WITH CEDILLA]
  '\u0106', // Ć  [LATIN CAPITAL LETTER C WITH ACUTE]
  '\u0108', // Ĉ  [LATIN CAPITAL LETTER C WITH CIRCUMFLEX]
  '\u010C', // Č  [LATIN CAPITAL LETTER C WITH CARON]
  '\u00E7', // ç  [LATIN SMALL LETTER C WITH CEDILLA]
  '\u0107', // ć  [LATIN SMALL LETTER C WITH ACUTE]
  '\u0109', // ĉ  [LATIN SMALL LETTER C WITH CIRCUMFLEX]
  '\u010D', // č  [LATIN SMALL LETTER C WITH CARON]
  '\u00C8', // È  [LATIN CAPITAL LETTER E WITH GRAVE]
  '\u00C9', // É  [LATIN CAPITAL LETTER E WITH ACUTE]
  '\u00CA', // Ê  [LATIN CAPITAL LETTER E WITH CIRCUMFLEX]
  '\u00CB', // Ë  [LATIN CAPITAL LETTER E WITH DIAERESIS]
  '\u00E8', // è  [LATIN SMALL LETTER E WITH GRAVE]
  '\u00E9', // é  [LATIN SMALL LETTER E WITH ACUTE]
  '\u00EA', // ê  [LATIN SMALL LETTER E WITH CIRCUMFLEX]
  '\u00EB', // ë  [LATIN SMALL LETTER E WITH DIAERESIS]
  '\u00CC', // Ì  [LATIN CAPITAL LETTER I WITH GRAVE]
  '\u00CD', // Í  [LATIN CAPITAL LETTER I WITH ACUTE]
  '\u00CE', // Î  [LATIN CAPITAL LETTER I WITH CIRCUMFLEX]
  '\u00CF', // Ï  [LATIN CAPITAL LETTER I WITH DIAERESIS]
  '\u00EC', // ì  [LATIN SMALL LETTER I WITH GRAVE]
  '\u00ED', // í  [LATIN SMALL LETTER I WITH ACUTE]
  '\u00EE', // î  [LATIN SMALL LETTER I WITH CIRCUMFLEX]
  '\u00EF', // ï  [LATIN SMALL LETTER I WITH DIAERESIS]
  '\u00D1', // Ñ  [LATIN CAPITAL LETTER N WITH TILDE]
  '\u00F1', // ñ  [LATIN SMALL LETTER N WITH TILDE]
  '\u00D2', // Ò  [LATIN CAPITAL LETTER O WITH GRAVE]
  '\u00D3', // Ó  [LATIN CAPITAL LETTER O WITH ACUTE]
  '\u00D4', // Ô  [LATIN CAPITAL LETTER O WITH CIRCUMFLEX]
  '\u00D5', // Õ  [LATIN CAPITAL LETTER O WITH TILDE]
  '\u00D6', // Ö  [LATIN CAPITAL LETTER O WITH DIAERESIS]
  '\u00D8', // Ø  [LATIN CAPITAL LETTER O WITH STROKE]
  '\u00F2', // ò  [LATIN SMALL LETTER O WITH GRAVE]
  '\u00F3', // ó  [LATIN SMALL LETTER O WITH ACUTE]
  '\u00F4', // ô  [LATIN SMALL LETTER O WITH CIRCUMFLEX]
  '\u00F5', // õ  [LATIN SMALL LETTER O WITH TILDE]
  '\u00F6', // ö  [LATIN SMALL LETTER O WITH DIAERESIS]
  '\u00F8', // ø  [LATIN SMALL LETTER O WITH STROKE]
  '\u015A', // Ś  [LATIN CAPITAL LETTER S WITH ACUTE]
  '\u015C', // Ŝ  [LATIN CAPITAL LETTER S WITH CIRCUMFLEX]
  '\u015E', // Ş  [LATIN CAPITAL LETTER S WITH CEDILLA]
  '\u0160', // Š  [LATIN CAPITAL LETTER S WITH CARON]
  '\u015B', // ś  [LATIN SMALL LETTER S WITH ACUTE]
  '\u015D', // ŝ  [LATIN SMALL LETTER S WITH CIRCUMFLEX]
  '\u015F', // ş  [LATIN SMALL LETTER S WITH CEDILLA]
  '\u0161', // š  [LATIN SMALL LETTER S WITH CARON]
  '\u00D9', // Ù  [LATIN CAPITAL LETTER U WITH GRAVE]
  '\u00DA', // Ú  [LATIN CAPITAL LETTER U WITH ACUTE]
  '\u00DB', // Û  [LATIN CAPITAL LETTER U WITH CIRCUMFLEX]
  '\u00DC', // Ü  [LATIN CAPITAL LETTER U WITH DIAERESIS]
  '\u00F9', // ù  [LATIN SMALL LETTER U WITH GRAVE]
  '\u00FA', // ú  [LATIN SMALL LETTER U WITH ACUTE]
  '\u00FB', // û  [LATIN SMALL LETTER U WITH CIRCUMFLEX]
  '\u00FC', // ü  [LATIN SMALL LETTER U WITH DIAERESIS]
  '\u00DD', // Ý  [LATIN CAPITAL LETTER Y WITH ACUTE]
  '\u0176', // Ŷ  [LATIN CAPITAL LETTER Y WITH CIRCUMFLEX]
  '\u0178', // Ÿ  [LATIN CAPITAL LETTER Y WITH DIAERESIS]
  '\u00FD', // ý  [LATIN SMALL LETTER Y WITH ACUTE]
  '\u00FF', // ÿ  [LATIN SMALL LETTER Y WITH DIAERESIS]
  '\u0177', // ŷ  [LATIN SMALL LETTER Y WITH CIRCUMFLEX]
  '\u017D', // Ž  [LATIN CAPITAL LETTER Z WITH CARON]
  '\u017E', // ž  [LATIN SMALL LETTER Z WITH CARON]
];
